exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-solarrechner-tsx": () => import("./../../../src/pages/solarrechner.tsx" /* webpackChunkName: "component---src-pages-solarrechner-tsx" */),
  "component---src-templates-job-detailpage-tsx": () => import("./../../../src/templates/job-detailpage.tsx" /* webpackChunkName: "component---src-templates-job-detailpage-tsx" */),
  "component---src-templates-news-detailpage-tsx": () => import("./../../../src/templates/news-detailpage.tsx" /* webpackChunkName: "component---src-templates-news-detailpage-tsx" */),
  "component---src-templates-news-paginated-tsx": () => import("./../../../src/templates/news-paginated.tsx" /* webpackChunkName: "component---src-templates-news-paginated-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-reference-detailpage-tsx": () => import("./../../../src/templates/reference-detailpage.tsx" /* webpackChunkName: "component---src-templates-reference-detailpage-tsx" */)
}

